@font-face {
  font-family: 'icomoon';
  src:  url('..//webfonts/icomoon.eot?2uxwne');
  src:  url('..//webfonts/icomoon.eot?2uxwne#iefix') format('embedded-opentype'),
    url('..//webfonts/icomoon.ttf?2uxwne') format('truetype'),
    url('..//webfonts/icomoon.woff?2uxwne') format('woff'),
    url('..//webfonts/icomoon.svg?2uxwne#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
    font-family: 'pt_root_uibold';
    src: url('..//webfonts/pt_root_ui_bold-webfont.woff2') format('woff2'),
         url('..//webfonts/pt_root_ui_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'pt_root_uilight';
    src: url('..//webfonts/pt_root_ui_light-webfont.woff2') format('woff2'),
         url('..//webfonts/pt_root_ui_light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'pt_root_uimedium';
    src: url('..//webfonts/pt_root_ui_medium-webfont.woff2') format('woff2'),
         url('..//webfonts/pt_root_ui_medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'pt_root_uiregular';
    src: url('..//webfonts/pt_root_ui_regular-webfont.woff2') format('woff2'),
         url('..//webfonts/pt_root_ui_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
	font-family: 'ds-digitalbold_italic';
	src: url("../webfonts/ds-digit-webfont.eot");
	src: url("../webfonts/ds-digit-webfont.eot?#iefix") format("embedded-opentype"), url("../webfonts/ds-digit-webfont.woff") format("woff"), url("../webfonts/ds-digit-webfont.ttf") format("truetype"), url("../webfonts/ds-digit-webfont.svg#ds-digit-webfont") format("svg");
	font-weight: normal;
	font-style: italic;
}

$text: "Helvetica Neue", Verdana, sans-serif;
$font: 'pt_root_uiregular', Verdana, sans-serif;
$fontBold: 'pt_root_uibold', Verdana, sans-serif;
$fontLight: 'pt_root_uilight', Verdana, sans-serif;
$fontMedium: 'pt_root_uimedium', Verdana, sans-serif;
$timeFont: 'ds-digitalbold_italic';