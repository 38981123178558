@import "mixins/noselect";
@import "mixins/rounded";
@import "include/fonts";

$sideMenuWidth: 240px;
$headerHeight: 50px;

$largeScreen: 768px;

.hide-web {
	display: none;
}

header {
	@include no-select;
	display: flex;
	position: fixed;
	top:0;
	height:$headerHeight;
	width:100%;
	padding:10px;
	z-index: 2;
	transition: all ease-in-out 200ms;
	-webkit-transition: 0.6s;
	-moz-transition: 0.6s;
	transition: 0.6s;
	align-items: center;
	
	
	.toggle {
		padding: 0px 10px 0px 10px;
		display:inline-block;
		cursor: pointer;
	}
		
	.navigationButtons {
		flex: 1;
		display: flex;
		justify-content:flex-start;
	}
	
	#backButton {
		padding-left: 5px;
		cursor: pointer;
	}
	
	.title {
		line-height: 1.1em;
		overflow: hidden;
		display: flex;
		justify-content:flex-start;
		h1 {
			overflow: hidden;
			padding: 0 0 0 5px;
			white-space: nowrap;
			font-family: $font;
			text-overflow: ellipsis;
		}
	}
	
	.actions {
		display: flex;
		flex: 1;
		justify-content: flex-end;

		#mainaction {
			padding: 0;
			div {
				max-width: 33vw;
				padding: 15px 5px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;			
			}
		}

		i {
			padding: 0px 10px 0px 10px;
		}
		
		> * {
			cursor: pointer;
			padding: 15px 5px;
		}
		
		#search {			
			input[type="text"] {
				width: 100%;
				height: 36px;
				font-size: 1.1em;
				padding-left: 19px;
				padding-right: 19px;
			}
			
			label {
				cursor: pointer;
				width: 100%;
				height: 36px;
				display: block;
				padding: 10px 20px;
			}
			
			input[type="radio"] {
				display: none;
			}
			
			input[type="radio"]:checked + .fa-circle:before {
				content: "\f058";
			}

		}
	}
	
}

main {
	padding:$headerHeight+10px 10px 10px 10px;
	position: relative;
	z-index: 0;
	-webkit-transition: 0.6s;
	-moz-transition: 0.6s;
	transition: 0.6s;
	
	[data-action],[data-click] {
		cursor: pointer;
	}
}

.page-wrap {
//	overflow-x: hidden;
//	overflow-y: auto;
	overflow: visible;
	font-family: $text;
}

.sidebar {
	overflow-y: auto;
	overflow-x: hidden;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: -$sideMenuWidth;
	width: $sideMenuWidth;
	z-index: 2;

	-webkit-transition: 0.6s;
	-moz-transition: 0.6s;
	transition: 0.6s;

	white-space: nowrap;

	li {
		@include no-select;	
		display:block;
		-webkit-font-smoothing: antialiased;

		list-style: none;
		
		&.active,&:hover {
			transition: 300ms;
		}
		
		i {
			text-align: center;
			width: 30px;
		}
		
		> div, > label > div {		
			cursor: pointer;
			padding: 15px 10px 15px 10px;
		}
		
	}
		
	.split {
		display: inline-flex;
		width: 190px;
		
		div {
			&.startSplit {
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				flex-shrink: 1;
			}
			
			&.endSplit {
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				max-width: 190px;
				flex-shrink: 0;
			}
		}
	}
	
	.item {
		display: inline-flex;
	}
	
	.badge {
		top: 13px;
		margin-left: 15px; 
		transform: translateX(-50%);
		
		&:empty {
			display: none;
			
		}

		&:not(:empty) {
			& + i {
				opacity: 0;
			}
		}
	} 
	
	
	.userinformation {
		padding: 15px;
		cursor: default;

		.maincontainer {
			display: flex;
			.imagecontainer {
				img {
					@include rounded;
					height: 55px;
				}			
			} 
			
			.namecontainer {
				padding: 2px 5px 5px 8px;
				
				.firstName {
					display: block;
				}
				
				.lastName {
					display: block;
				}

			}
		}


		&.active,&:hover {
			transition: none;
		}
	}

}
 
#side {
	display: none;
	&:checked + .page-wrap {
		.sidebar {
			transform: translateX($sideMenuWidth);
			li {
				font-size: 16px;
				&.sidemenu-item {
					position: relative;
				}

				i.quick-add {
					font-size: 0.7em;
					position: absolute;
					padding-top: 2px;
					right: 5px;
				}						
				
				&.sidemenu-sub {
					position: relative;
					
					input[type="checkbox"] {
						display: none;
					}
					
					> div {
						padding: 0;
						border: 0;
						
						i.submenu-opened, i.submenu-closed {
							position: absolute;
							right: 5px;
							top: 14px;
							pointer-events: none;
						}
						
						i.submenu-opened {
							display: none;
						}
						
						i.submenu-closed {
							display: block;
						}
												
						ul {
							display: none;
							li > div {
								padding-left: 25px;
							}
						}
						
						i.quick-add {
							font-size: 0.7em; 
							position: absolute;
							padding-top: 2px;
							right: 5px;
						}						 
					}
					
					.split {
						padding-left: 5px;
					}
					
					input:checked ~ div {
						ul {
							display: block;						
						}
						i.submenu-opened {
							display: block;
						}
						i.submenu-closed {
							display: none;
						}
						
					}
					input:checked ~ label {
						.badge {
							display: none;
						}
						i {
							opacity: 1;
						} 
					}
				} 
			}
		}
		@media only screen and (min-width: $largeScreen) {
			main {
				padding-left: ($sideMenuWidth+10px);
			}
			#debug {
				margin-left: ($sideMenuWidth+10px);
				max-width: calc(100% - 260px);
			}
			#profile {
				transform: translateX($sideMenuWidth);
			}
		}
		header {
			padding-left:$sideMenuWidth;
		}
	}
	
}

#analyser {
	padding-top: 50px;
}

.swal2-toast-shown .swal2-container.swal2-top-end {
	top: 50px !important;
	opacity: 0.8;
	pointer-events:none;
}

.user-selector, .element-selector{
	ul.user-selector-selected, ul.element-selector-selected {
		li {
			.user-selector-img-container, .element-selector-img-container {
				&:hover {
					cursor: pointer;
					i.fa-times-circle {
						display: inline-block;	
					}
				}

				i.fa-times-circle {
					display: none;
				}
			}
		}
	}

}


/* STICKY */
.sticky-header {
	top:$headerHeight; 
}

#calendar {
	.header {
		top: $headerHeight;
	}
	&.calendar-week-full, &.calendar-day {
		.calendar {
			.week {
				.week-container {
					.week-fullday {
						top:61px+$headerHeight;
					}
					.week-number {
						top:41px+$headerHeight;
					}
				}
				.day {
					.day-header {
						top:41px+$headerHeight;
					}
					.day-appointments {
						.day-fullday {
							top:61px+$headerHeight;
						}
					}
				}
			}
		}
	}
}